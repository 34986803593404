<template>
  <RadioGroup v-model="selected">
    <RadioGroupLabel class="sr-only">{{ $t('listing.weight-selection') }}</RadioGroupLabel>
    <div class="space-y-3">
      <RadioGroupOption as="template" v-for="i in weights" :key="i.weight" :value="i.weight" v-slot="{ active, checked }">
        <div :class="[active ? 'border-primary-600 ring-2 ring-primary-600/50' : 'border-gray-300', 'relative cursor-pointer rounded-lg border bg-white px-6 py-2.5 shadow-sm focus:outline-none flex items-center justify-between']">
          <span class="flex items-center">
            <span class="flex flex-col text-sm">
              <RadioGroupLabel as="span" class="font-medium text-gray-900">{{ i.weight }} / gr.</RadioGroupLabel>
            </span>
          </span>
          <RadioGroupDescription as="span" class="flex text-sm sm:ml-4 mt-0 flex-col text-right">
            <span class="font-medium text-gray-900" :class="{'opacity-40 line-through' : getDiscountedPrice(i.price)}">{{ i.price }} TL</span>
            <span class="font-medium text-gray-900" v-if="getDiscountedPrice(i.price)">{{ getDiscountedPrice(i.price) }} TL</span>
          </RadioGroupDescription>
          <span :class="[active ? 'border' : 'border-2', checked ? 'border-primary-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
const props = defineProps(['weights', 'discount', 'callback'])
const emit = defineEmits(['update:modelValue'])

const selected = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})

const getDiscountedPrice = (price) => {
  if (props.discount?.type === 'percent') {
    return price - ((price / 100) * props.discount?.value);
  } else if (props.discount?.type === 'value') {
    return price - props.discount?.value;
  } else {
    return null
  }
}

</script>

<style scoped>

</style>