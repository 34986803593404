<template>
  <div>
    <!-- Input Number -->
    <div class="flex  border rounded-md shadow-sm bg-white overflow-hidden" :class="wrapperClass">
      <!-- Button -->
      <button
          class="text-xl h-9 w-9 flex justify-center items-center"
          :class="
          disabled || readonly || !increasable
            ? 'text-lightgray-300 cursor-not-allowed'
            : 'text-lightgray-900'
        "
          type="button"
          tabindex="-1"
          :disabled="disabled || readonly || !increasable"
          @click="increase"
          v-if="direction === 'vertical' || direction === 'horizontal' && value > 0"
      >
        <LucidePlus class="w-3.5 h-3.5" />
      </button>

      <FormInput
        custom-class="block w-full border-none text-center h-9 w-9"
        ref="input"
        type="text"
        v-model="value"
        :size="inputSize"
        @keydown.enter="(e) => setValue(e.target.value)"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readonly || !inputtable"
        :disabled="disabled || (!decreasable && !increasable)"
        :class="({ 'is-error': error }, [customClass], {'!text-gray-400 cursor-not-allowed' : disabled})"
        :placeholder="placeholder"
        autocomplete="off"
        v-if="value > 0 && !isWeight"
      />

      <!-- Button -->
      <button
          class="text-xl h-9 w-9 flex justify-center items-center"
          :class="
          disabled || readonly || !decreasable
            ? 'text-lightgray-300 cursor-not-allowed'
            : 'text-lightgray-900'
        "
          type="button"
          tabindex="-1"
          :disabled="disabled || readonly || !decreasable"
          @click="decrease"
          v-if="value > 0"
      >
        <LucideTrash2 class="w-3.5 h-3.5" v-if="value === 1 || isWeight" />
        <LucideMinus class="w-3.5 h-3.5" v-else />
      </button>

      <button class="btn w-full !py-1.5 !rounded-none m-0" :class="addToCartButtonClass" @click.prevent="addToCart" v-if="value === 0 && direction !== 'vertical'" >
        {{$t('app.add-to-cart')}}
      </button>

      <ClientOnly>
        <Teleport to="body">
          <FormModal :title="$t('basket.select-weight')" width-class="max-w-xs" v-model="isWeightModelShown">
            <ProductWeightSelection :weights="weights" :discount="discount" v-model="value" />
            <FormButton color="success" class="w-full mt-3" :loading="loading" @click.prevent="addToCart(true)">{{$t('app.add-to-cart')}}</FormButton>
          </FormModal>
        </Teleport>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup>
import {TrashIcon, PlusIcon} from '@heroicons/vue/24/outline'
import ProductWeightSelection from "~/components/Section/ProductWeightSelection.vue";
const isNaN = Number.isNaN || window.isNaN
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/
const normalizeDecimalNumber = (value, times = 100000000000) => (
    REGEXP_DECIMALS.test(String(value)) ? (Math.round(value * times) / times) : value
)

const props = defineProps({
  modelValue: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: Infinity,
  },
  min: {
    type: Number,
    default: -Infinity,
  },
  step: {
    type: Number,
    default: 1,
  },
  inputtable: {
    type: Boolean,
    default: true,
  },
  readonly: Boolean,
  rounded: Boolean,
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  formHelper: {
    type: String,
    default: "",
  },
  labelHelper: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  customClass: {
    type: String,
    default: null
  },
  addToCartButtonClass: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  inputSize : {
    type : String,
    default : null
  },
  onChange : {
    type : Function,
    default : () => null
  },
  direction : {
    type : String,
    default : 'vertical'
  },
  isWeight : {
    type: Boolean,
    default: false,
  },
  weights : {
    type : Array,
    default : []
  },
  discount : {
    default : {}
  }
})
const emit = defineEmits(['update:modelValue', 'change'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

const isWeightModelShown = ref(false)

const wrapperClass = computed(() => props.direction === 'horizontal' ? 'flex-row items-center w-full justify-between px-2.5' : 'flex-col items-center w-9 divide-y')

/**
 * Indicate if the value is increasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const increasable = computed(() => isNaN(unref(value)) || unref(value) < props.max)

/**
 * Indicate if the value is decreasable.
 * @returns {boolean} Return `true` if it is decreasable, else `false`.
 */
const decreasable = computed(() => isNaN(unref(value)) || unref(value) > props.min)

/**
 * Decrease the value.
 */
const decrease = () => {
  if (props.isWeight) {
    setValue(0)
    return;
  }

  if (decreasable.value) {
    if (isNaN(unref(value))) {
      value.value = 0;
    }
    setValue(normalizeDecimalNumber(unref(value) - props.step));
  }
}
/**
 * Increase the value.
 */
const increase = () => {
  if (increasable.value) {

    if (props.isWeight) {
      isWeightModelShown.value = true;
      return;
    }

    if (isNaN(unref(value))) {
      value.value = 0;
    }
    setValue(normalizeDecimalNumber(unref(value) + props.step));
  }
}

/**
 * Set new value and dispatch change event.
 * @param {number} value - The new value to set.
 */
const setValue = (val) => {
  const oldValue = val

  let newValue = typeof val !== 'number' ? parseFloat(val) : val

  if (!isNaN(newValue)) {
    if (props.min <= props.max) {
      newValue = Math.min(props.max, Math.max(props.min, newValue));
    }
    if (props.rounded) {
      newValue = Math.round(val);
    }
  }

  value.value = newValue

  // if (newValue === oldValue) {
  //   // Force to override the number in the input box (#13).
  //   (this.$refs.input).value = String(newValue);
  // }

  emit('change', newValue)
}

const addToCart = async (isForce = false) => {
  if (props.isWeight && !isForce) {
    isWeightModelShown.value = true;
  } else {
    await increase()
    props.onChange.call().then(() => {
      if (props.isWeight) {
        isWeightModelShown.value = false;
        // value.value = 0;
      }
    })
  }
}

watch(() => props.modelValue, (newValue, oldValue) => {
  if (
      // Avoid triggering change event when created
      !(isNaN(newValue) && typeof oldValue === 'undefined')
      // Avoid infinite loop
      && newValue !== value.value
  ) {
    setValue(newValue);
    props.onChange.call()
  }
})

watch(isWeightModelShown, (newValue) => {
  if (newValue) {
    value.value = 1000
  } else {
    value.value = 0
  }
})

</script>
