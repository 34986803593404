<template>
  <div class="flex flex-col items-center justify-start group relative">
    <div class="relative">
      <div class="product-is-new absolute m-2" v-if="selectedVariant?.isNew">
        {{ $t('app.new') }}
      </div>

      <div class="group">

        <DiscountPercent :selected-variant="selectedVariant" v-if="!showRateNextToPrice"/>

        <FormAddCartCounter
            class="absolute right-0 top-0 z-10 p-2"
            v-model="cartQuantity"
            :max="selectedVariant?.unitType === 2 ? selectedVariant?.primaryStockData.quantity * 1000 : selectedVariant?.primaryStockData.quantity"
            :step="selectedVariant?.unitType === 2 ? 0.1 : quantityStep"
            input-size="sm"
            :is-weight="selectedVariant?.unitType === 2"
            :loading="isLoading"
            :weights="weights"
            :discount="discountConfig"
            :onChange="() => addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, selectedWarehouse.value, selectedVariant?.unitType !== 2, false, true)"
        />

        <div class="rounded-lg relative border border-lightgray overflow-hidden">
          <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="block bg-white">
            <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
              <span class="product-out-of-stock"><StockOutText /></span>
            </div>

            <div class="flex absolute z-20 justify-center text-center items-end w-full h-full" v-if="selectedVariant?.isFreeShipping">
                <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                  <BoltIcon class="h-5 w-5 mr-2 fill-primary"/>
                  <span>{{ $t('app.free-shipping') }}</span>
                </span>
            </div>

            <NuxtImg
                v-if="selectedVariant?.thumbnail"
                :src="getImage(selectedVariant.thumbnail)"
                width="300"
                height="300"
                fit="outside"
                loading="lazy"
                :placeholder="[50, 50, 93, 3]"
                :quality="90"
                :alt="selectedVariant?.title"
                :class="{'product-img-out-of-stock' : !selectedVariant?.inStock}"
                class="z-1 rounded-lg object-contain aspect-square mx-auto"
            />

            <NuxtImg
                v-else
                fit="outside"
                alt=""
                class="z-1 rounded-lg object-contain aspect-square"
                width="300"
                height="300"
                src="/images/no-image.png"
            />
          </NuxtLinkLocale>

          <!-- Ürün Seçenekleri -->
          <SectionProductCardGroupOptions :product="product" />

        </div>
        <div class="flex flex-col text-base text-primary">
          <div class="font-medium text-md line-clamp-2 mt-2">{{ selectedVariant?.title }}</div>

          <!-- Fiyat Bilgisi -->
          <SectionProductCardPriceView :variant="selectedVariant" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {HeartIcon, BoltIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import TriangleIcon from '~/assets/icons/triangle.svg'
import AddToCartIcon from '~/assets/icons/add-to-cart.svg'

import {collect} from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";
import PriceUpdateModeDescriptionForB2B from "~/components/PriceUpdateModeDescriptionForB2B.vue";
import {customRound} from "~/composables/useHelper";

const props = defineProps(['product'])

const storeConfig = useStoreConfig()
const { baseConfig, showRateNextToPrice } = storeToRefs(storeConfig)

const {
  selectedWarehouse,
  hidePriceForSoldOut,
  showListPrice,
  hideBrand
} = useListing()

const {$listen} = useNuxtApp()

const {currentPage} = usePagination()

const isLoading = ref(false)

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

const variants = await collect(props.product.variants);

const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

const {addToBasket, addCartLoading, basketItems} = await useBasket()
const {isWholesaleUser, authModalIsVisible} = await useCustomer()
const {addWishlist} = useWishlist()

const cartQuantity = ref(0)
const quantityStep = computed(() => selectedVariant.value?.primaryStockData?.quantityStep)
const discountConfig = computed(() => selectedVariant.value?.primaryStockData?.discount?.config)

const weights = computed(() => {
  if (selectedVariant.value?.primaryStockData?.weights.length < 1) {
    return []
  }

  return collect(selectedVariant.value?.primaryStockData?.weights).sortByDesc('weight').toArray()
})


/**
 * Eğer ürünler sepette ekli ise, ekli olan ürünlerin sayfa yenileme gibi işlemlerde hatırlanması için kontrol sağlıyoruz
 */

watch([basketItems, currentPage], async ([cartVal, currentPageVal]) => {
  const cartQty = await collect(cartVal).where('variant_id', '=', selectedVariant.value.variantId).pluck('qty').first()
  if (cartQty) {
    cartQuantity.value = selectedVariant.value?.unitType === 2 ? cartQty * 1000 : cartQty
  }
})

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>